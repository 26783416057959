import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueBodyClass from 'vue-body-class';

Vue.use(VueRouter)

let searchTabs = ['video', 'images', 'news'];

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { bodyClass: 'home' },
  },
  {
    path: '/results/:searchType?',
    name: 'Results',
    props: route => {
      return {
        query: route.query.q || route.params.query,
        searchType: searchTabs.indexOf(route.params.searchType) > -1 ? route.params.searchType : 'web'
      }
      
    },
    component: () => import('../views/Results.vue')
  },
  {
    path: '/mobile-app',
    name: 'MobileApp',
    component: () => import('../views/MobileApp.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/vpn',
    name: 'VPN',
    component: () => import('../views/Vpn.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/submit-url',
    name: 'SubmitUrl',
    component: () => import('../views/SubmitUrl.vue')
  },
  {
    path: '/newsfeed',
    name: 'Newsfeed',
    component: () => import('../views/Newsfeed.vue')
  },
  {
    path: '/rockitfuel',
    name: 'RockitFuel',
    component: () => import('../views/RockitFuel.vue')
  },
  {
    path: '/unstoppable-domains',
    name: 'UnstoppableDomains',
    component: () => import('../views/UnstoppableDomains.vue')
  },
  {
    path: '/rpm',
    name: 'RPM',
    component: () => import('../views/RPM.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

export default router
