<template>
  <nav
    v-scroll="handleScroll"
    role="banner"
    :class="{ 'is-minimal': isMinimal }"
    class="navbar is-fixed-top top-bar is-align-items-center"
  >
    <router-link to="/"
      ><img class="logo" src="/images/SearchRockitNav.png" alt="SearchRockit"
    /></router-link>
    <img
      class="mobile-logo"
      src="/images/SearchRockitLogo2.png"
      alt="SearchRockitLogo"
    />
    <site-menu link-class="has-text-white" class="top-site-menu" />
    <search-input class="is-block search-input" />
    <img
      class="full-logo"
      src="/images/SearchRockitLogo2.png"
      alt="SearchRockitLogo"
    />
  </nav>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import SiteMenu from "@/components/SiteMenu";
export default {
  name: "TopBar",
  components: { SearchInput, SiteMenu },
  data: () => ({
    isMinimal: false,
  }),
  methods: {
    handleScroll(evt, el) {
      if (window.scrollY > 80) {
        this.isMinimal = true;
      } else {
        this.isMinimal = false;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
//@import '@/assets/scss/_vars.scss';
.mobile-logo {
  display: none;
}
.logo {
  width: 100px;
  min-width: 100px;
  position: relative;
  top: 5px;
  padding-right: 50px;
}
.search-input {
  width: 616px;
  padding-left: 40px;
}
.top-bar {
  padding: 40px;
  display: flex;
  background-image: url("/images/TopNavSearchRockt.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0);
  transition: all 200ms ease-out;
  &.is-minimal {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.4);
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.full-logo {
  margin-left: 40px;
  width: 275px;
  margin-top: -10px;
}
@media screen and (max-width: 1200px) {
  .full-logo {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .top-bar {
    padding: 30px;
    display: block;
    background-size: auto;
  }
  .search-input {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  .top-site-menu {
    position: absolute;
    top: 33px;
    right: 20px;
  }
  .logo {
    display: none;
  }
  .full-logo {
    display: none;
  }
  .mobile-logo {
    display: block;
    margin-bottom: 20px;
    width: 210px;
    max-width: 100%;
  }
  .mobile-menu-button {
    margin-right: 0;
  }
}
</style>