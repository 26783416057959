<template>
  <div>
    <section class="blade search-input-section has-text-centered">
      <div class="full-size-bg has-background-blue-3">
        <picture role="presentation">
          <source srcset="/images/home-bg.jpg" type="image/jpeg" />
          <img class="full-size-bg-img" src="/images/home-bg.jpg" />
        </picture>
      </div>

      <div class="rockit-logo">
        <img
          class="logo"
          src="/images/SearchRockitNav.png"
          alt="SearchRockit"
          width="70"
          height="110"
        />
      </div>

      <div
        class="search-input-content is-flex is-flex is-justify-content-center"
      >
        <div class="content">
          <img
            class="logo mb-2"
            src="/images/SearchRockitLogo2.png"
            alt="SearchRockit"
          />
          <div class>
            <a
              class="has-text-white"
              style="font-weight: 800"
              href="/mobile-app"
              >DOWNLOAD THE FREE APP</a
            >
          </div>
          <search-input class="is-block search-input" />
          <div class="below-search has-text-white">
            <div class="is-size-5 mb-3">
              Our trackless searches tap into a billion plus indexed pages and
              growing
            </div>
            <div class="is-size-6">
              <span class="has-text-weight-bold">Wormhole Technology™</span>
            </div>
            <div class="is-size-6 mb-6 pb-5">
              <span class="no-break">https encryption</span> / <span class="no-break">No tracking cookies</span> / <span class="no-break">No data selling</span> / <span class="no-break">No logs</span>
            </div>
            <div>
              <span class="has-text-weight-bold">POWERED BY GIBIRU™</span>
            </div>
            <div>1.2 Billion unique URL's</div>
          </div>
        </div>
      </div>

      <div @click="$scrollTo('#why')" class="more-arrow-container">
        <down-arrows></down-arrows>
      </div>
    </section>

    <div class="content">
      <section class="img-container">
        <img
          class="footprint"
          src="/images/SearchRockitFootprint.jpg"
          alt="No tracking ever"
        />
        <img style="" src="/images/SearchRockitBarriers.jpg" alt="Beyond Big Tech Borders" />
        <img
          class="rockit-fuel"
          src="/images/SearchRockitWarp.jpg"
          alt="Warp Speed Searches"
        />
      </section>

      <section class="has-background-light has-text-blue-6">
        <div class="container py-6">
          <div class="columns is-centered">
            <div class="column is-9-desktop">
              <h2 class="has-text-black">How is SearchRockit™ Different?</h2>

              <p>
                SearchRockit is a new App designed to explore the vast reaches
                of information beyond the Big Tech without being tracked,
                monitored, or surveilled as you search. SearchRockit also plugs
                into Gibiru's™ massive and growing unique URL's not throttled by
                Big Tech censorship and search distortions.
              </p>

              <p>
                <router-link class="has-text-blue-6" to="/mobile-app"
                  >Download <strong>App Now</strong></router-link
                >
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import DownArrows from "@/components/DownArrows";

export default {
  name: "Home",
  components: { SearchInput, DownArrows },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.search-input-section {
  height: 100vh;
  min-height: 817px;
  position: relative;
}
.img-container {
    img {
      width: 33%;
    }
  
    .footprint {
      float: left;
      margin-right: 0.5%;
    }
  
    .rockit-fuel {
      float: right;
    }
  
    @media screen and (max-width: 768px) {
      img {
        width: 100%;
      }
  
      .footprint {
        float: none;
        margin-right: 0;
      }
  
      .rockit-fuel {
        float: none;
      }
    }
  }

.rockit-logo {
  position: absolute;
  top: 24px;
  left: 86px;
  z-index: 2;
  @media screen and (max-width: 1500px) {
    top: 40px;
    left: auto;
    position: relative;
  }
}

.below-search {
  max-width: 500px;
  margin: 0 auto;
}

.search-input-content {
  z-index: 2;
  align-items: center;
  height: 100%;
  position: relative;

  @media screen and (max-width: 1500px) {
    align-items: start;
    padding-top: 50px;
  }

  & > .content {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    h1 {
      font-weight: 800;
      font-size: 1.5em;
    }
    h2 {
      font-weight: 800;
      font-size: 1.5em;
    }
    p {
      font-size: 17px;
      margin-top: 2.8em;
    }
  }
}

.search-input {
  width: 100%;
  max-width: 560px;
  margin: 25px auto 50px;
}

.more-arrow-container {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  z-index: 2;
  cursor: pointer;
  &:hover .more-arrow {
    transform: scale(1.5);
  }
}
</style>
