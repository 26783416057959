<template>
  <div>
    <nav class="desktop-menu">
      <ul class="is-flex">
        <li class="menu-item" v-for="(menuItem, i) in menuItems" :key="i">
          <router-link
            class="menu-item-link"
            :class="[linkClass, i === menuItems.length - 1 ? 'last' : '']"
            :to="menuItem.link"
            >{{ menuItem.text }}</router-link
          >
        </li>
        <li v-if="isHomePage" class="ml-5">
          <b-button tag="router-link" to="mobile-app" type="is-red-1 is-small"
            >Download the free mobile app
            <img class="app-icon" width="20" src="/images/icon-apple.png" />
            <img class="app-icon" width="20" src="/images/icon-android.png" />
          </b-button>
          <external-link href="https://www.facebook.com/imarockitman"
            ><img class="fb-icon" src="/images/FBicon.jpg" alt="Facebook"
          /></external-link>
        </li>
      </ul>
    </nav>

    <div class="mobile-menu-button">
      <b-icon
        :class="linkClass"
        icon="menu"
        size="is-large"
        @click.native="showNavPanel = true"
      >
      </b-icon>
    </div>

    <b-sidebar
      type="is-primary"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="true"
      v-model="showNavPanel"
    >
      <nav class="mobile-menu">
        <div class="has-text-right">
          <b-icon
            class="has-text-white"
            icon="close"
            size="is-medium"
            aria-label="close"
            @click.native="showNavPanel = false"
          >
          </b-icon>
        </div>
        <ul>
          <li
            class="menu-item mb-5"
            v-for="(menuItem, i) in menuItems"
            :key="i"
          >
            <router-link
              @click.native="showNavPanel = false"
              class="menu-item-link has-text-white"
              :class="[i === menuItems.length - 1 ? 'last' : '']"
              :to="menuItem.link"
              >{{ menuItem.text }}
            </router-link>
          </li>
          <li class="menu-item mb-5">
            <b-button class="mobile-app-button" tag="router-link" to="mobile-app" type="is-red-1"
              ><span>Get the mobile app</span>
            </b-button>
          </li>
          <li>
            <external-link href="https://www.facebook.com/imarockitman"
              ><img class="fb-icon" src="/images/FBicon.jpg" alt="Facebook"
            /></external-link>
          </li>
        </ul>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
import ExternalLink from "./ExternalLink.vue";
export default {
  components: { ExternalLink },
  name: "SiteMenu",
  props: {
    linkClass: {
      type: String,
      default: "has-text-white",
    },
    isHomePage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menuItems: [
      { text: "Search", link: "/" },
      { text: "About", link: "/about" },
      { text: "Web 3 Unstoppable Domains", link: "/unstoppable-domains" },
      //{text: 'Download the free mobile app', link: '/mobile-app'},
    ],
    showNavPanel: false,
  }),
};
</script>

<style lang="scss" scoped>
.desktop-menu .fb-icon {
  width: 30px;
  margin-left: 13px;
}
.app-icon {
  opacity: 0.8;
  vertical-align: bottom;
  margin: 0 1px;
}
.menu-item-link {
  font-weight: 600;
  text-transform: uppercase;
}
.desktop-menu .menu-item-link {
  font-size: 20px;
  margin-right: 12px !important;
  &::after {
    margin-left: 12px !important;
    content: " " !important;
  }
  &.last {
    margin-right: 0 !important;
    &::after {
      margin-left: 0 !important;
      content: none !important;
    }
  }
}
.mobile-menu-button {
  display: none;
  margin-right: 5px;
}
.mobile-menu {
  padding: 20px 16px 20px 20px;
}
.mobile-menu .menu-item-link {
  font-size: 18px;
}

@media screen and (max-width: 1500px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu-button {
    display: block;
  }
}
</style>