<template>
  <footer class="footer content has-text-centered has-text-grey">
    <div class="links">
      <div class="m-3">
        <div>&copy; {{ year }} {{ siteName }}™</div>
      </div>

      <div class="m-3">
        <router-link class="has-text-grey is-uppercase" :to="{ name: 'About' }">About</router-link>
      </div>

      <div class="m-3">
        <external-link class="has-text-grey is-uppercase" :href="$settings.UNSTOPPABLE_LINK">Web3 Unstoppable Domains</external-link>
      </div>

      <div class="m-3">
        <router-link class="has-text-grey is-uppercase" :to="{ name: 'SubmitUrl' }">Submit URL</router-link>
      </div>

      <div class="m-3">
        <router-link class="has-text-grey is-uppercase" :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
      </div>

      <div class="m-3">
        <a class="has-text-grey is-uppercase" href="#" @click.prevent="$eventBus.$emit('showFeedback')">Send Feedback</a>
      </div>
    </div>

    <div>
      SearchRockit™ is a US company privately held and committed to your search privacy
    </div>

    <div class="disclaimer mt-6 mx-auto">
      © SEARCHROCKIT™. ALL RIGHTS RESERVED. ALL PRODUCT NAMES, TRADEMARKS AND REGISTERED TRADEMARKS ARE PROPERTY OF THEIR RESPECTIVE OWNERS. ALL COMPANY, PRODUCT AND SERVICE NAMES USED ARE FOR IDENTIFICATION PURPOSES ONLY. USE OF THESE NAMES, TRADEMARKS AND BRANDS DOES NOT IMPLY ENDORSEMENT.
    </div>
    
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  data: () => ({
    year: new Date().getFullYear(),
    siteName: process.env.VUE_APP_SITE_TITLE,
  }),
};
</script>

<style lang="scss" scoped>
footer{
   font-size: 13px;
  .links {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 800px){
        display: block;
    }
  }
  .disclaimer{
    max-width: 1180px;
    font-size: 12px;
  }
}
</style>